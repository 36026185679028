<script setup lang="ts">
import { TariffsIcon } from '../../tariffs';
import { type TariffItem, usePaymentTariffs } from '@/src/features/tariffs';
import { UiDarkBBButton, UiInput } from '~/src/shared/ui';
import { useSidebarStore } from '~/src/widgets/terminal/store';
import { saveSumData } from '~/src/features/top-up/infrastructure/store';

const tariffs = usePaymentTariffs();

const sum = ref('');
const tariff = ref<TariffItem | null>(null);
const tariffClick = ref<boolean>(false);

const sumModel = computed({
	get() {
		return String(tariff.value?.sum || sum.value);
	},

	set(value) {
		if (tariff.value && !tariffClick.value) {
			tariff.value = null;
		}
		sum.value = String(value);
		tariffClick.value = false;
	},
});

const apply = () => {
	const sidebarStore = useSidebarStore();
	saveSumData({
		tariff: tariff.value,
		sum: +sum.value || tariff.value?.sum,
	});
	sidebarStore.goBack();
};
</script>

<template>
	<div class="flex flex-col gap-8">
		<div class="flex flex-col gap-4">
			<UiInput
				v-model="sumModel"
				label="Введите сумму платежа"
				placeholder="0"
				size="regular"
				mask-type="currency"
			/>

			<span class="text-sm font-normal text-gray-500">
				Или выберите готовый вариант:
			</span>

			<div class="flex flex-col gap-3">
				<button
					v-for="item in tariffs"
					:key="item.id"
					type="button"
					class="bg-gray-600 rounded-lg px-3 py-2 flex items-center gap-3 justify-between"
					@click="() => { tariff = item; tariffClick=true; }"
				>
					<span class="text-base font-bold text-white">
						{{ `$ ${item.sum}` }}
					</span>

					<div class="flex gap-1 items-center px-4 py-0.5">
						<TariffsIcon
							:type="item.code"
							class="text-[16px]"
						/>

						<span class="text-white text-sm font-medium">
							{{ item.name }}
						</span>
					</div>
				</button>
			</div>
		</div>

		<UiDarkBBButton
			v-if="sum || tariff"
			size="sm"
			button-text="Подтвердить"
			@click="apply"
		/>
	</div>
</template>
